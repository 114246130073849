const moment = require('moment');
import localForage from 'localforage';
import { Browser } from '@capacitor/browser';

export default {

  toggleView (view) {
    const query = !!view ? _.isObject(view) ? view : { view } : null; //_.omit(this.$route.query, 'view');
    this.$router.push({ query });
  },

  toggleHelp (b, context, section, item) {
    // this.help.toggle = b; 
    if (b) {
      this.toggleView({ view: 'help', context, section, item });
    }else{
      this.toggleView();
    }
  },

  toggleTicket (id) {
    this.toggleView({ view: 'help', ticket: id });
  },

  toggleEvent (id) {
    this.toggleView({ view: 'help', section: 'events', event: id });
  },

  format (fields, dictionary, outwards=false) {
    return _.mapValues(_.mapKeys(fields, (field, f) => {
      return outwards ? _.isObject(dictionary[f]) ? dictionary[f].key : dictionary[f] : _.findKey(dictionary, d => _.isObject(d) ? d.key : d==f);
    }), (field, f) => {
      return _.isObject(field) ? _.map(field, item => {
        return !_.isObject(item) ? item : _.mapValues(_.mapKeys(item, (subfield, s) => {
          return outwards ? dictionary[f][s] : _.findKey(dictionary[f], d => d==s);
        }), (subfield, s) => {
          return s.indexOf('At')>=0&&subfield!=null ? this.formatDate(subfield, outwards) : subfield
        });
      }) : f.indexOf('At')>=0&&field!=null ? this.formatDate(field, outwards) : field
    });
  },

  formatDate (value, outwards) {
    if (moment(value).isValid()) {
      if (outwards) {
        value = moment(value).utc();
      }else{
        value = moment.utc(value).local();
      }
    }
    return value.format('YYYY-MM-DD HH:mm:ss');
  },

  formatText (text) {
    const reg = {
      link: /\[([^\[]+)\]\(([^\)]+)\)(\^?)/gim, // [text](link) ^ can be added at end to set the target as 'blank'
      bold: /\*([^\*]+)\*/gim, // *bold*
      emphasis: /\*\*([^\*]+)\*\*/gim, // **emphasis**
      nowrap: /--([^\*]+)--/gim, // --nowrap--
      br: /\n/gim,
    }
    return !!text ? text
      .replace(reg.link, (match, $1, $2, $3) => {
        var _target = $3 ? "blank" : ""; // check if '^' sign is present with link syntax
        // check if is app route or file
        const appURL = this.getAppRoot();
        const external = !_.startsWith($2, '/') && !_.startsWith($2, appURL);
        const file = /[^/\\&\?#]+\.\w{3,4}(?=([\?&#].*$|$))/.test($2);
        const link = "<a " + (external||file ? "data-popup "+(file ? "data-file" : "") : "") + "href='" + $2 +"'>" + $1 + "</a>";
        return (link);
      })
      .replace(reg.bold, "<b>$1</b>")
      .replace(reg.emphasis, "<em>$1</em>")
      .replace(reg.nowrap, "<span class='text-no-wrap'>$1</span>")
      .replace(reg.br, "<br/>") : '';
  },

  async popup (url) {
    await Browser.open({ url });
  },

  getAppRoot() {
    const url = process.env.VUE_APP_ROOT;
    return url;
  },

  translateNotation(field) {
    return this.dictionary[field];
  },

  selectText (element, copy=false, message='Texto copiado!') {
    window.getSelection().selectAllChildren(element);
    if (copy) {
      const text = _.trim(element.innerText);
      navigator.clipboard.writeText(text).then(() => {
        this.toggleToast(true, message)
      });
    }
  },

  loadHelpContent (local, file, key) {
    file = _.isNil(file) ? '/help/content.json' : file;
    key = _.isNil(key) ? new Date().valueOf() : key;
    const url = (local ? `/help/content.json` : this.appURL+file)+'?k='+key;
    fetch(url, { cache: 'no-store' }).then(r => r.json()).then((json) => {
      const content = json[0];
      console.log(content);
      if (!local) {
        this.checkHelpCompatibility(content, (local ? 'local' : '')+file);
      }else{
        console.log('setHelpContent', file);
        this.helpContent = Object.assign({}, content);
        this.help.version = { compatible: content.controller.compatible, file: (local ? 'local' : '')+file };
      }
    }).catch(error => {
      console.log('loadHelpContent', error);
      this.handleError(error, 'Failed to load '+file);
      this.loadHelpContent(true);
      // setTimeout(($) => {
      //   $.loadHelpContent(false);
      // }, 5000, this);
    });
  },

  checkHelpCompatibility (content, url) {
    const controller = content.controller;
    // Help version
    const [native, web] = _.split(controller.compatible, '/');
    const app = this.version;
    if (!_.has(app, 'native')||parseInt(app.native)>=parseInt(native)) {
      console.log('setHelpContent Compatible v', controller.compatible);
      this.helpContent = Object.assign({}, content);
      this.help.version = { compatible: controller.compatible, url };
    }else{
      const best = _.find(controller.versions, v => {
        return parseInt(app.native)>=parseInt(_.split(v.compatible, '/')[0]);
      })
      if (_.isNil(best)) {
        console.log('loadHelpContent local...', null);
        this.loadHelpContent(true);
      }else{
        console.log('loadHelpContent Compatible found v', best.compatible);
        this.help.version = best;
        this.loadHelpContent(false, best.url)
      }
    }
  },

  getUserCity (user) {
    const address = user.profile.address;
    const city = !!address.city&&!!address.state ? address.city + '/' + address.state : null;
    return city;
  },

  locationEnabled () {
    return navigator.permissions.query({name:'geolocation'}).then(function(result) {
      return result.state === 'granted';
    });
  },

  handleError (error, msg, toast) {
    let timer = 7000;
    if (!this.isValid(msg)) {
      console.log('Network/Server Error', error);
      msg = 'Deu erro aqui! Vamos investigar o problema 😊';
    }
    if (!this.isOnline) {
      msg = 'Sem conexão com a internet.';
      timer = -1;
      console.log('Offline');
    }
    toast = this.isValid(toast) ? toast : false;
    if (toast||!this.isOnline) {
      this.toggleToast(
        true,
        msg,
        timer,
        false
      );
    }
    // if a `message` property exists or not
    const exception = !this.isOnline ? 'Offline' : _.isNil(msg) ? _.has(error, 'message') ? error.message : error : msg;
    this.$gtag.exception(_.isString(exception)||!_.has(exception, 'description') ? { description: exception } : exception);
  },

  trackEvent (category, action, label, value) {
    let event = {};
    if (this.isValid(category)) event['event_category'] = category;
    if (this.isValid(label)) event['event_label'] = label;
    if (this.isValid(value)) event['value'] = value;

    this.$gtag.event(action, event);
  },

  shouldUpdate (loader) {
    // console.log(loader.updatedAt, moment(loader.updatedAt).add(...loader.lazyness));
    return (!loader.loading && loader.updatedAt==null) || moment().isAfter(moment(loader.updatedAt).add(...loader.lazyness));
  },

  isValid (data) {
    return typeof data != 'undefined' ? data != null : false;
  },
  slugify (text, file=false) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
    const s = file ? /[^\w\-.]+/g : /[^\w\-]+/g
  
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(s, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  },
  rawCPF (cpf) {
    return !!cpf ? cpf.replace(/^[.-]+|[.-]+$|[.-]+/g, "") : null;
  },
  rawData (data) {
    if (_.isNil(data)) console.log('profile', data);
    return !_.isNil(data) ? data.replace(/[^\w]/gi, '') : null;
  },

  logout (cpf, token, ghost=null) {
    if (!!ghost) {
      this.getout();
      return;
    }
    this.$api
      .get(process.env.VUE_APP_ROOT_API+'/logout/'+cpf+'/'+token+'/?format=json')
      .then(response => {
        console.log('logout => ',response);
        if(response.data[0].retorno==200||response.data[0].retorno==401){
          console.log('logged out');
          this.trackEvent('access', 'logout');
          this.getout();
        }else{
          this.toggleToast(
            true,
            'Não foi possível realizar o logout.',
            5000,
            false,
          );
        }
      })
      .catch(error => {
        // console.log('Network/Server Error', error);
        // this.toggleToast(
        //   true,
        //   'Erro na solicitação.',
        //   7000,
        //   false,
        //   'error'
        // );
        this.getout();
      });
  },

  getout () {
    this.delStorage('user').then(() => {
      localForage.removeItem('vuex', () => {
        window.location.href = '/';
      });
    });
  },

  toggleToast (toggle, text, timeout=7000, button, color) {
    if(typeof button == 'undefined'||button===false) {
      button = {
        toggle: false,
        text: 'Ok',
        action: false
      };
    }
    if(typeof color == 'undefined'){
      color = 'var(--city-blue-50)';
    }
    this.$set(this, 'toast', {
      toggle,
      text,
      timeout,
      button,
      color
    });
  },

  normalize (data) {
    return this.isValid(data) && typeof data == 'string' ? data.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '';
  },

  insert (string, inside, index) {
    return [inside.slice(0, index), string, inside.slice(index, inside.length)].join('');
  },
  
  cancelToasts () {
    if (this.toast.toggle) {
      this.toast.toggle = false;
    }
  },

  setStorage (key, value, type='localStorage') {
    return Promise.resolve().then(() => {
      if (_.isObject(value)) value = JSON.stringify(value);
      window[type].setItem(key, value);
    });
  },
  getStorage (key, type='localStorage') {
    return Promise.resolve().then(async () => {
      let value = await window[type].getItem(key);
      if (_.startsWith(value, '{')||_.startsWith(value, '[')||value=='true'||value=='false') value = JSON.parse(value);
      return value;
    });
  },
  delStorage (key, type='localStorage') {
    return Promise.resolve().then(function () {
      window[type].removeItem(key);
    });
  },
}